import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { AuthUtils } from "app/core/auth/auth.utils";
import { UserService } from "app/core/user/user.service";
import { catchError, Observable, of, switchMap, tap, throwError } from "rxjs";
import { appConfig } from "../config/app.config.dev";
import { User } from "../user/user.types";
import { SettingService } from "../setting/setting.service";
import { APP_BASE_HREF } from "@angular/common";
import { jwtDecode } from "jwt-decode";
import { AppConfigService } from "app/app-config.service";

@Injectable({ providedIn: "root" })
export class AuthService {
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);
    private _userSettingService = inject(SettingService);
    private _appConfigService = inject(AppConfigService);

    public status: string = "";
    public iframeUrl: string = '';

    private basePath = this._appConfigService.getConfigSubject();

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    _user: User;

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem("accessToken", token);
    }

    get accessToken(): string {
        return localStorage.getItem("accessToken") ?? "";
    }

    /**
     * Setter & getter for refresh token
     */
    set refreshToken(token: string) {
        localStorage.setItem("refreshToken", token);
    }

    get refreshToken(): string {
        return localStorage.getItem("refreshToken") ?? "";
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param username
     */
    forgotPassword(username: string): Observable<any> {
        return this._httpClient.post(this.basePath.value + "auth/v1/password/forgot", { username });
    }

    /**
     * Reset password
     *
     * @param new_password
     * @param code
     * @param username
     */
    resetPassword(new_password: any, code: any, username?: any): Observable<any> {
        let body = {
            username: username,
            new_password: new_password,
            code: code
        };
        return this._httpClient.post(this.basePath.value + "auth/v1/password/renew", body);
    }

    /**
     * validate secrete
     *
     * @param code
     * @param secretQuestion
     * @param secrete
     */
    valdiateSecrete(code: any, secretQuestion: any, secrete: any): Observable<any> {
        let body = {
            code: code,
            secretQuestion: secretQuestion,
            secrete: secrete
        };
        return this._httpClient.post(this.basePath.value + "auth/v1/validate/secrete", body);
    }

    authViaGoogle(code: string): Observable<any> {
        if (this._authenticated) {
            return throwError("User is already logged in.");
        }

        return this._httpClient.get(this.basePath.value + "auth/v1/signin/social/google?ott=" + code).pipe(
            switchMap((response: any) => {
                if (response.accessToken != undefined) {

                    var tokenExpiry = Date.now() + parseInt(response.expiry, 10);
                    sessionStorage.setItem("tokenExpiry", tokenExpiry.toString());

                    // Store the access token in the local storage
                    this.accessToken = response.accessToken;
                    // Set the authenticated flag to true
                    this._authenticated = true;

                    this._userService.getUserDetails().subscribe(userResponse => {
                        this._userService.getSignatureImage().subscribe(responseImage => {
                            if (
                                responseImage.image != "" ||
                                responseImage.image != null ||
                                responseImage.image != undefined
                            ) {
                                let signatureImageContent = responseImage.image;
                                let imgElementSrc = "data:image/png;base64," + signatureImageContent;

                                localStorage.setItem("signature", imgElementSrc);

                                let user: User = {
                                    id: response.id,
                                    name: userResponse.name,
                                    email: "credentials.usernameOrEmail",
                                    username: userResponse.username,
                                    signature: imgElementSrc,
                                    mfa: response.mfa
                                };

                                // Store the user on the user service
                                this._userService.user = user;
                                localStorage.setItem("directory", user.username);
                                localStorage.setItem("email", "credentials.usernameOrEmail");

                                // this.changeDetectorRef.detectChanges();
                            } else {
                                let user: User = {
                                    id: response.id,
                                    name: response.name,
                                    email: "credentials.usernameOrEmail",
                                    username: response.username,
                                    signature: "imgElementSrc",
                                    mfa: response.mfa
                                };

                                // Store the user on the user service
                                this._userService.user = user;
                                localStorage.setItem("directory", user.username);
                                localStorage.setItem("email", "credentials.usernameOrEmail");
                            }
                        });
                    });
                }

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { username: string; password: string; grant_type: string; tOtp?: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError("User is already logged in.");
        }

        return this._httpClient.post(this.basePath.value + "auth/v1/signin", credentials).pipe(
            switchMap((response: any) => {
                if (response.accessToken != undefined) {
                    var tokenExpiry = response.expires_in;
                    sessionStorage.setItem("tokenExpiry", tokenExpiry);

                    // Store the access token in the local storage
                    this.accessToken = response.accessToken;
                    this.refreshToken = response.refreshToken;

                    // Set the authenticated flag to true
                    this._authenticated = true;
                    this.status = "ACTIVE";

                    this._userService.getSignatureImage().subscribe(responseImage => {
                        if (
                            responseImage.image == "" ||
                            responseImage.image == null ||
                            responseImage.image == undefined
                        ) {
                            let user: User = {
                                id: this._user?.id,
                                name: this._user?.name,
                                email: credentials.username,
                                username: this._user?.username,
                                signature: "imgElementSrc",
                                mfa: this._user?.mfa
                            };

                            // Store the user on the user service
                            this._userService.user = user;
                            localStorage.setItem("directory", user.username);
                            localStorage.setItem("email", credentials.username);
                        } else {
                            let signatureImageContent = responseImage.image;
                            let imgElementSrc = "data:image/png;base64," + signatureImageContent;

                            localStorage.setItem("signature", imgElementSrc);

                            let user: User = {
                                id: this._user?.id,
                                name: this._user?.name,
                                email: credentials.username,
                                username: this._user?.username,
                                signature: imgElementSrc,
                                mfa: this._user?.mfa
                            };

                            // Store the user on the user service
                            this._userService.user = user;
                            localStorage.setItem("directory", user.username);
                            localStorage.setItem("email", credentials.username);
                        }
                    });
                }

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    // checkByUrlAndToken(url: any): Observable<any> {
    //     return this._httpClient.get<User>(this.basePath.value + "user/v1/settings/profile").pipe(
    //         catchError(() =>
    //             // Return false
    //             of(false)
    //         ),
    //         switchMap((user: User) => {
    //             this._user = user;

    //             // this is necessary for refresh access token
    //             this._authenticated = true;

    //             //Check if user is guest
    //             if (url != "") {
    //                 if (user.status == "GUEST") {
    //                     if (url.includes("wait") || url.includes("documents/prepare") || url.includes("feedback")) {

    //                         return of(true);
    //                     } else {
    //                         this._authenticated = false;

    //                         return of(false);
    //                     }
    //                 } else {
    //                     return of(true);
    //                 }
    //             }
    //         })
    //     );
    // }

    checkByUrlAndToken(url: any): Observable<any> {
        return this._httpClient.get<User>(this.basePath.value + "user/v1/settings/profile").pipe(
            catchError(() =>
                // Return false if an error occurs while fetching the profile
                of(false)
            ),
            switchMap((user: User) => {
                this._user = user;

                // Set authenticated to true
                this._authenticated = true;

                // Check if a URL is provided
                if (url != "") {
                    // If user status is not ACTIVE
                    if (user.status !== "ACTIVE") {
                        // Allow access only to specific routes
                        if (url.includes("wait") || url.includes("documents/prepare") || url.includes("feedback")) {
                            return of(true);
                        } else {
                            // Deny access to other routes
                            this._authenticated = false;
                            return of(false);
                        }
                    } else {
                        // If user is ACTIVE, allow access to all routes
                        return of(true);
                    }
                }
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient
            .post("api/auth/sign-in-with-token", {
                accessToken: this.accessToken
            })
            .pipe(
                catchError(() =>
                    // Return false
                    of(false)
                ),
                switchMap((response: any) => {
                    // Replace the access token with the new one if it's available on
                    // the response object.
                    //
                    // This is an added optional step for better security. Once you sign
                    // in using the token, you should generate a new one on the server
                    // side and attach it to the response object. Then the following
                    // piece of code can replace the token with the refreshed one.
                    if (response.accessToken) {
                        this.accessToken = response.accessToken;
                    }

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response.user;

                    // Return true
                    return of(true);
                })
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        this.iframeUrl = undefined;
        let tokenType: any = jwtDecode(this.accessToken);

        if (tokenType.authType && tokenType.authType !== 'CLIENT') {
            this.revokeToken(this.refreshToken, this.accessToken).subscribe(
                success => {
                    console.log("token revoked successfully");
                },
                failure => {
                    console.error(failure);
                }
            )
        }

        localStorage.clear();

        // Clear the cached user data
        this._userService.clearUserCache();

        // Clear the cached user setting data
        this._userSettingService.clearUserSettingCache();

        // Set the authenticated flag to false
        this._authenticated = false;

        this.status = "";

        // Return the observable
        return of(true);
    }

    revokeToken(refreshToken: string, accessToken: string): Observable<any> {

        let token = refreshToken;
        let token_type_hint = "refresh_token"

        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        });
        return this._httpClient.post(this.basePath.value + 'auth/v1/revoke-token', { token, token_type_hint }, { headers });
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        name: string;
        username: string;
        emailAddress: string;
        password: string;
        // organization: string;
    }): Observable<any> {
        return this._httpClient.post(this.basePath.value + "auth/v1/signup", user);
    }

    /**
     *
     * @param code
     */

    verifyAccount(code: string): Observable<any> {
        return this._httpClient.get(this.basePath.value + "auth/v1/verifyAccount/" + code);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post("api/auth/unlock-session", credentials);
    }

    /**
     * Check the authentication status
     */
    check(redirectURL?: string): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        return this.checkByUrlAndToken(redirectURL).pipe(
            tap(isAuthenticated => {
                if (isAuthenticated) {
                    this._authenticated = true;
                }
            })
        );
    }

    // Validate Code 2FA

    // validateCode(code: string): Observable<any> {
    //     return this._httpClient.get(this.basePath.value + "auth/v1/verifyLink/" + code);
    //     // return this._httpClient.get<Map<string, string>>(`${this.basePath.value}/verifyLink/${code}`);
    //   }

    isMFARequired(email: string): Observable<boolean> {
        const url = `${this.basePath.value}auth/v1/mfaRequired?email=${encodeURIComponent(email)}`;
        return this._httpClient.get<boolean>(url);
    }

    validateClientApp(clientToken: string, docId: number): Observable<any> {
        const token = clientToken;

        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        });

        return this._httpClient.get(this.basePath.value + "auth/v1/clientAppVerify/" + docId, { headers }).pipe(
            switchMap((response: any) => {
                if (response.valid) {
                    sessionStorage.setItem("tokenExpiry", "100000");
                    this.iframeUrl = response.callBackUrl;

                    // Store the access token in the local storage
                    this.accessToken = clientToken;

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    this._userService.getUserDetails().subscribe(userResponse => {
                        // this._userService.getSignatureImage().subscribe((responseImage) => {

                        // if (responseImage.image == "" || responseImage.image == null || responseImage.image == undefined) {

                        let user: User = {
                            id: userResponse.id,
                            name: userResponse.name,
                            email: userResponse.email,
                            username: userResponse.username,
                            signature: "imgElementSrc",
                            mfa: userResponse.mfa
                        };

                        // Store the user on the user service
                        this._userService.user = user;
                        localStorage.setItem("directory", user.username);
                        localStorage.setItem("email", userResponse.email);

                        // } else {
                        //     let signatureImageContent = responseImage.image;
                        //     let imgElementSrc = "data:image/png;base64," + signatureImageContent;

                        //     localStorage.setItem("signature", imgElementSrc);

                        //     let user: User = {
                        //         id: userResponse.id,
                        //         name: userResponse.name,
                        //         email: userResponse.email,
                        //         username: userResponse.username,
                        //         signature: imgElementSrc,
                        //         mfa: userResponse.mfa,
                        //     };

                        //     // Store the user on the user service
                        //     this._userService.user = user;
                        //     localStorage.setItem("directory", user.username);
                        //     localStorage.setItem("email", userResponse.email);
                        // }
                        // });
                    });
                }

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    // *************************************** Get General Settings ***************************************** //
    getAppUrl(options?: any): Observable<any> {
        return this._httpClient.get(this.basePath.value + `auth/v1/app-url`, options);
    }
}
